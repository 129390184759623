<template>
  <div class="bg">
    <div class="container">
      <h1>Uzbechka - Отзывы из зала #8</h1>
      <v-alert
        v-if="popup === true"
        :color="classPopUp"
        :icon="'$' + classPopUp"
        :text="textPopUp"
      ></v-alert>
      <form @submit.prevent="formSubmit" v-if="popup !== true">
        <label for="selectedDish">К чему относится отзыв</label>
        <v-select
          v-model="selectedHall"
          clearable
          id="selectedHall"
          :items="halls"
          variant="outlined"
          :rounded="'rounded-pill'"
        ></v-select>
        <label for="selectedDish">Блюдо</label>
        <v-autocomplete
          variant="outlined"
          v-model="selectedDish"
          :items="dishes"
          @update:model-value="selectedDish"
          rounded="rounded-xl"
        >
        </v-autocomplete>
        <label for="selectedGrade">Отзыв</label>
        <v-textarea
          id="selectedCommentDish"
          variant="outlined"
          v-model="comment"
          rounded="rounded-xl"
        ></v-textarea>
        <v-file-input v-model="file" label="Загрузите файл"></v-file-input>
        <v-btn
          variant="tonal"
          type="submit"
          size="large"
          :disabled="
            this.formState === 'send'
              ? true
              : comment === '' || selectedDish === ''
              ? true
              : false
          "
          class="btn"
        >
          Отправить
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      formState: "wait",
      selectedHall: "Кухня",
      halls: ["Кухня", "Бар", "Прочее"],
      selectedDish: "",
      grade: 0.3,
      comment: "",
      popup: false,
      textPopUp: "",
      classPopUp: "",
      file: null,
      dishes: [],
    };
  },
  async beforeMount() {
    const res = await axios.get("https://api.uzbechka.rest:5000/menu-uzb-sar");

    const dishes = res.data.reduce((acc, cur) => {
      cur.items.forEach((x) => acc.push(x.name));

      return acc;
    }, []);

    this.dishes = JSON.parse(JSON.stringify(dishes));
  },
  methods: {
    async formSubmit() {
      this.formState = "send";

      let message = `<b>Новый отзыв!</b>\n`;
      message += `Зал: <b>${this.selectedHall}</b>\n`;
      message += `Блюдо: <b>${this.selectedDish}</b>\n`;
      message += `Комментарий: <b>${this.comment}</b>\n`;
      message += `\n`;
      message += `Дата отправки <b>${new Date().toLocaleDateString()}</b>\n`;

      await axios
        .post(process.env.VUE_APP_URL_TEXT_TELEGRAM, {
          chat_id: process.env.VUE_APP_CHAT_UZBECHKA_8_ID,
          parse_mode: "html",
          text: message,
        })
        .then((res) => {
          this.textPopUp = "Ваша заявка успешно отправленна!";
          this.classPopUp = "success";
        })
        .catch((err) => {
          this.textPopUp = "Произошла какая-то ошибка! Попробуйте еще раз!";
          this.classPopUp = "error";
        });

      this.file &&
        (await axios
          .post(
            process.env.VUE_APP_URL_FILE_TELEGRAM,
            {
              chat_id: process.env.VUE_APP_CHAT_UZBECHKA_8_ID,
              parse_mode: "html",
              document: this.file,
            },
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((res) => {
            this.textPopUp = "Ваша заявка успешно отправленна!";
            this.classPopUp = "success";
          })
          .catch((err) => {
            this.textPopUp = "Произошла какая-то ошибка! Попробуйте еще раз!";
            this.classPopUp = "error";
          }));

      const formData = new FormData();

      formData.append(
        "time",
        `${new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`
      );
      formData.append("date", `${new Date().toLocaleDateString()}`);
      formData.append("hall", this.selectedHall);
      formData.append("dish", this.selectedDish);
      formData.append("comment", this.comment);

      await axios
        .post(process.env.VUE_APP_GSHEETS_UZBECHKA_8, formData)
        .then((result) => {
          console.log("Данные успешно отправлены в таблицу");
        })
        .catch((error) => {
          console.error("Ошибка при отправке данных в таблицу");
        });
      this.popup = true;

      this.comment = "";
      this.formState = "wait";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,700&display=swap");

.bg {
  background-image: url("../assets/img/uzbechka.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100%;
  max-height: fit-content;
  width: 100%;
  padding: 50px 0px;
}

.container {
  width: 700px;
  margin: 20px auto 50px;
  background-color: white;
  padding: 40px;
  box-sizing: border-box;
  border: 0;
  border-radius: 60px;
}

@media (max-width: 700px) {
  .container {
    width: 500px;
  }

  .title-form {
    font-size: 80px;
  }
}

@media (max-width: 500px) {
  .container {
    width: 340px;
  }

  .title-form {
    font-size: 48px;
  }
}

.v-btn {
  display: flex;
  border: 0px;
  border-radius: 0px;
  background-color: #c8b9a0;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 300;
  color: #41464b;
}

.v-btn--disabled {
  background-color: grey;
  color: #41464b;
}

.v-btn.v-btn--density-default {
  height: 60px;
  width: 200px;
}
.btn {
  position: relative;
  margin: 0 auto;
}

.v-slider.v-input {
  margin-top: 30px;
}

#selectedDish[data-v-e50ac4b0] > .v-input__control > .v-field {
  border-radius: 50px !important;
}

h1 {
  font-family: "Roboto";
  font-weight: 700;
  /* text-align: center; */
  text-transform: uppercase;
  font-size: 40px;
}

label {
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 400;
  color: #000;
}
form:deep(.v-field) {
  border-radius: 0px;
}

@media (max-width: 1000px) {
  h1 {
    font-size: 30px;
  }

  label {
    font-size: 20px;
  }

  .v-btn {
    font-size: 16px;
    width: 100%;
  }

  .v-btn.v-btn--density-default {
    height: 40px;
    width: 160px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 24px;
  }

  label {
    font-size: 20px;
  }

  .v-btn {
    width: 100% !important;
  }
}
</style>
